const VIP = () => {
    return (
        <>
            <div className="row text-center mb-5">
                <h3>VIP Benefits</h3>
            </div>
            <div className="row justify-content-md-center text-center">
                <p className="mb-5">Join the Eternal Wings Club today for access to exclusive shopper benefits, and earn as you shop! Becoming a member is easy - simply visit the VIP terminal at Everfaery's mainstore location and pay a one-time fee to join! You won't need to join a group and your VIP status is permanent, and rewards can be received without needing any group tag (unless otherwise noted).</p>
                <div className="col-lg-8 col-sm-12">
                    <table className="comparison">
                    <thead>
                        <tr>
                            <th></th>
                            <th className="w-25">Non-Members</th>
                            <th className="w-25">Members</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Store credit on every purchase</td>
                            <td><i className='bx bx-x' ></i></td>
                            <td>10%</td>
                        </tr>
                        <tr>
                            <td>Access to exclusive free gifts at the mainstore</td>
                            <td><i className='bx bx-x' ></i></td>
                            <td><i className='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td>Claimable L$250 store credit during your avatar's birth month</td>
                            <td><i className='bx bx-x' ></i></td>
                            <td><i className='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td>Group tag required - Monthly L$75 store credit for being a member of the in-world update group</td>
                            <td><i className='bx bx-x' ></i></td>
                            <td><i className='bx bx-check'></i></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default VIP;
