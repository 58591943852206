import SocialLinks from '../ui/socials';

const Follow = () => {
    return (
        <>
            <div className="text-center mb-5">
                <h3>Follow Everfaery</h3>
            </div>
            <div className="text-center row">
                <p>Want to stay in the loop and be the first to know Everfaery news? Consider joining the group or subscribing to the mailing list to receive updates!</p>

                <div className="col-lg-6 p-4">
                    <h4 className="mt-5">Join the In-World Group</h4>
                    <hr/>
                    <p>As a group member, you'll receive the latest news and sales plus access to exclusive perks such as group gifts, giveaways, and sales events. To join, search for "everfaery" in the group directory, or use this link directly in your browser or paste into SL chat: <a href="secondlife:///app/group/af20982b-bc66-ed99-5e2f-672c34c116f2/about">secondlife:///app/group/af20982b-bc66-ed99-5e2f-672c34c116f2/about</a></p>
                </div>

                <div className="col-lg-6 p-4">
                    <h4 className="mt-5">Subscribe</h4>
                    <hr />

                <p>If you don't want to use up a group slot, you can instead subscribe to the in-world mailing list. Visit the mainstore location and click on the "Subscribe" sign to subscribe or unsubscribe at any time! You'll miss out on group perks, but you'll still recieve news about the latest products and promotions.</p>
                </div>

            </div>
            <div className="row justify-content-md-center text-center mt-5">
                <p>You can also keep up with Everfaery outside of SL through social media:</p>
                <div className="col-lg-4 col-sm-12">    
                    <SocialLinks type="icon" />
                </div>
            </div>
        </>
    );
}

export default Follow;
