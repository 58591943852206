import DecoyLogo from "../assets/images/logo.png";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import StoreLinks from './stores';
import SocialLinks from './socials';

const HeaderNav = () => {
    return (  
        <Navbar collapseOnSelect expand="lg" variant="dark" static="top">
            <Container>
                <Navbar.Brand href="/"><img src={DecoyLogo} alt="Decoy" height="60"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto ms-auto">
                            <Link to="/" className="nav-link">Home</Link>
                        <Link to="/locations" className="nav-link">Locations</Link>
                        <Link to="/follow" className="nav-link">Follow</Link>
                        <Link to="/vip" className="nav-link">VIP</Link>
                            <Link to="/faq" className="nav-link">FAQ</Link>
                        <Link to="/support" className="nav-link">Support</Link>

                         
                        </Nav>
                        <Nav className="socials">
                            <StoreLinks type="icon"/>
                         </Nav>
                    </Navbar.Collapse>
            </Container>
      </Navbar>   
    );
}
 
export default HeaderNav;