import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const Support = () => {
    return (
        <>
            <div className="row text-center mb-5">
                <h3>Customer Support</h3>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-lg-4 col-sm-12 mb-5 text-center">
                        <h4><i className='bx bxs-store bx-tada-hover mt-3'></i> REDELIVERY</h4>
                        <hr />
                    <p>If you need to redeliver your previous purchase or a gift you received, visit the redelivery terminal at the mainstore</p>
                    <Button href="http://maps.secondlife.com/secondlife/everfaery/140/92/32" size="lg">Teleport to Everfaery</Button>
                </div>
                <div className="col-lg-4 col-sm-12 mb-5 text-center">
                    
                        
                            <h4><i className='bx bx-list-ul mt-3'></i> FAQ</h4>
                            <hr />
                    <p>Need help or have a question? It may already be answered on the Frequently Asked Questions!</p>
                    <Link to="/faq">
                        <Button size="lg">Visit the FAQ
                        </Button>
                    </Link>
                </div>
                <div className="col-lg-4 col-sm-12 mb-5 text-center">
                        <h4><i className='bx bxs-chat mt-3'></i> CONTACT SUPPORT</h4>
                        <hr />
                    <p>If you cannot solve your issue with other options, send a message directly to EverfaerySupport (no notecards)</p>
                    <Button href="secondlife:///app/agent/53590d85-8f13-42ba-aabf-0e3ffd45e0aa/about" size="lg">Message EverfaerySupport
                    </Button>
                    <div><span className="small-text">*Link will open the support profile directly in SL</span></div>
                </div>
            </div>
        </>
    );
}

export default Support;
