import axios from 'axios';
import { useState, useEffect } from "react";
import { FlickrAPI } from '../data/api';
import Button from 'react-bootstrap/Button'; 
import Spinner from 'react-bootstrap/Spinner';

const LoadingContent = () => {
	return (
		<div className="text-center">
			<Spinner animation="border" role="status">
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		</div>
	);
}

const Home = () => {
	const [fetchedData, setData] = useState(null);
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {	
		FlickrAPI.get().then((returnedData) => {
			setData(returnedData);
			setLoading(false);			
		})
	}, []);

	return (
		<div className="row mb-5">
			{isLoading && <LoadingContent />}

			{(!isLoading && fetchedData) && fetchedData.map((row, imgIndex) => (
					<div className="product-container col-lg-6 col-sm-12">
						<div>
							<img src={`https://live.staticflickr.com/${row.server}/${row.id}_${row.secret}_b.jpg`} className="gallery-thumbnail"></img>
						</div>
						<div className="my-auto text-center">
								<span className="title">{row.title}</span>
								<span className="link"><Button href={`https://www.flickr.com/photos/${row.owner}/${row.id}`} target="_blank">View Details</Button></span>
						</div>
					</div>
			))}
		</div>
	);	
}

export default Home;
