import { Route, Routes } from 'react-router-dom';


// Stylesheets
import './assets/css/reset.css';
import './assets/css/bootstrap.min.css';
import './assets/css/boxicons.min.css';
import './assets/css/main.css';


// Elements
import Footer from './ui/footer';
import HeaderNav from './ui/header';

// Pages
import Home from './pages/home';
import About from './pages/about';
import Locations from './pages/locations';
import FAQ from './pages/faq';
import Support from './pages/support';
import Policy from './pages/policy';
import FollowUs from './pages/follow';
import CustomerRedelivery from './pages/redelivery';
import VIP from './pages/vip';

const App = () => {

     return (
        <div className="App">
            <HeaderNav />
            <div className="container main-content">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="locations" element={<Locations />} />                         
                    <Route path="about" element={<About />}/>
                    <Route path="redelivery/*" element={<CustomerRedelivery />}/>
                     <Route path="support" element={<Support />} />
                    <Route path="faq" element={<FAQ />} />
                    <Route path="policy" element={<Policy />} />
                     <Route path="socials" element={<FollowUs />} />
                     <Route path="follow" element={<FollowUs />} />
                     <Route path="vip" element={<VIP />} />
                </Routes>
            </div>
            <Footer/>
        </div>
     );
}

export default App;
