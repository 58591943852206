import { Link } from 'react-router-dom';

const SubNav = () => {
    return (  
        <>
            <ul>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/policy">Store Policy</Link></li>
            </ul>
        </>
    );
}
 
export default SubNav;