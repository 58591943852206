import Button from 'react-bootstrap/Button';

const Locations = () => {
     return (
        <>               
            <div className="row text-center mb-5">
                <h3>Store Locations</h3>
            </div>
            <div className="row justify-content-md-center">
                 <div className="col-lg-4 col-sm-12 mb-5">
                    <Button href="http://maps.secondlife.com/secondlife/Everfaery/140/92/32" size="lg">
                        <i className='bx bxs-store circle-icon bx-tada-hover'></i>    
                        <p>Visit the in-world store to shop the lowest prices and view the full catalog of current products and gifts</p>
                    </Button>
                </div>  
                 <div className="col-lg-4 col-sm-12 mb-5">
                    <Button href="http://marketplace.secondlife.com/stores/60618" size="lg">
                        <i className='bx bxs-cart circle-icon bx-tada-hover' ></i>     
                        <p>Browse a smaller selection of new and older products online through the SL Marketplace</p>
                    </Button>
                </div>
            </div>
        </>
     );
}

export default Locations;
