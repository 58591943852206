import SubNav from '../ui/subnav';

const Policy = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-2 sidebar">
                    <SubNav />
                </div>
                <div className="col-lg-10">
                    <h3>Store Policy</h3>
                    <hr />

                    <div className="mb-5">
                        <h5>Usage</h5>
                        <p>Any item purchased from Everfaery is limited to personal-use only within Second Life <span>&reg;</span>. Items may not be resold, shared or transferred in any way to other avatars (unless the item was sold as a gacha item with no copy & transfer permissions). Items may not be reuploaded to other platforms, games, or worlds without prior consent. All items are copyrighted by Everfaery and legal action will be taken against individuals breaching these terms.</p>
                    </div>

                    <div className="mb-5">
                        <h5>Permissions</h5>
                        <p>Beginning August 2022, items will come with the following permissions: Yes Modify, Yes Copy, No Transfer unless otherwise stated on the ad. Requests for special permissions or customizations will not be granted.</p>
                    </div>

                    <div className="mb-5">
                        <h5>Refunds</h5>
                        <p>All sales are final and no refunds will be issued except for the following conditions:</p>
                        <ul className="bullet-list">
                            <li>
                                Duplicate purchases of the same item for the same avatar. A refund will be issued only for the most recent purchase back to the original form of payment (either in L$ or store credit).
                            </li>
                            <li>
                                Single-color purchases if the fatpack was purchased within 30 days of the original single-color purchase. Fatpacks must be purchased under the same selling conditions as the single colors (both items must either be on sale or regular price).
                            </li>
                        </ul>
                    </div>


                </div>
            </div>
        </>
    );
}

export default Policy;
