import SubNav from '../ui/subnav';
import SocialLinks from './socials';

const Footer = () => {
    return (  
     <footer className="fixed-bottom text-center">
            <SubNav />
            © everfaery.com {(new Date().getFullYear())}
            <SocialLinks type="icon" />
     </footer>
    );
}
 
export default Footer;