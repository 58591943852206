import Button from 'react-bootstrap/Button';

const inworld = "http://maps.secondlife.com/secondlife/everfaery/133/128/34";
const marketplace = "http://marketplace.secondlife.com/stores/60618";

const StoreLinks = ({ type }) => {
    return (
        <div className="iconLinks">
            {type == "icon" ?
                <div>
                    <a href={inworld} target="_blank" className="social-icon"><i className='bx bxs-store circle-icon bx-tada-hover'></i></a>
                    <a href={marketplace} target="_blank" className="social-icon"><i className='bx bxs-cart circle-icon bx-tada-hover'></i></a>
                </div>
                :
                <div>
                    <Button className="button-link" href={inworld} size="lg">Mainstore</Button>
                    <Button className="button-link" href={marketplace} size="lg">Marketplace</Button>
                </div>
            }

        </div>
    );
}

export default StoreLinks;