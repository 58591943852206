import SubNav from '../ui/subnav';

const About = () => {
     return (
         <>
             <div className="row">
                 <div className="col-lg-2 sidebar">
                    <SubNav />
                 </div>
                 <div className="col-lg-10">
                     <h3>About Everfaery</h3>
                     <hr />
                     <p>Founded in Second Life<span>&reg;</span> in 2007 as Decoy, we rebranded to Everfaery in 2023 to better reflect our vision of infusing virtual worlds with magic. We initially focused on women's fashion and accessories for over a decade until our love for creativity led us to explore new realms. Now, you'll find an exciting array of interactive accessories, homes, decor, furniture, and landscaping items, all designed to add a touch of magic into every aspect of your virtual life.</p>

                     <p>At Everfaery, we're passionate about creating high-quality, imaginative items with endless customization options, allowing you to truly make each piece your own. Our style blends casual and fantasy themes, offering perfect additions whether you're creating an enchanting space or looking for something for your everday adventures. Join us and discover the magic in every creation!</p> 
                 </div>
             </div>
          </>
     );
}

export default About;
