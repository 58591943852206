import { useState } from "react";
import { faqData } from "../data/questions";
import { v4 as uuidv4 } from "uuid";
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';

const MakeQuestion = ({question,answer,link, linkname}) => {
	const [showAnswer, setShowAnswer] = useState(false);

	return (
		<Accordion.Item eventKey={uuidv4()}>
			<Accordion.Header>{question}</Accordion.Header>
			<Accordion.Body>
				<p>{answer}</p>
				{link && 
					<p><a href={link} target="_blank">{linkname}</a> </p>
				}
				</Accordion.Body>
		</Accordion.Item>
	);
}

const FAQ = () => {
	return (
		<>
			<div className="text-center mb-5">
				<h3>Frequently Asked Questions</h3>
			</div>
			<div className="">
			<Accordion alwaysOpen>
				{faqData.map((questionAnswer) => (
					<MakeQuestion {...questionAnswer} key={uuidv4()}/>
				))}
			</Accordion>
			</div>
		</>
	);
}

export default FAQ;


