import Button from 'react-bootstrap/Button';

const flickr = "https://www.flickr.com/photos/annettevoight";
const fb = "https://www.facebook.com/everfaery/";
const insta = "https://www.instagram.com/everfaerysl";
const youtube = "https://www.youtube.com/@feverfae";
const flickrGroup = "https://www.flickr.com/groups/everfaery/";
const twitter = "https://twitter.com/everfaery";
const deviantart = "https://www.deviantart.com/everfaery";
const tumblr = "https://everfaery.tumblr.com/";

const SocialLinks = ({ type }) => {
    return (
        <div className="iconLinks">
            {type == "icon" ?
                <div>
                    <a href={flickr} target="_blank" className="social-icon"><i className='bx bxl-flickr circle-icon bx-tada-hover'></i></a>
                    <a href={fb} target="_blank" className="social-icon"><i className='bx bxl-facebook circle-icon bx-tada-hover'></i></a>
                    <a href={insta} target="_blank" className="social-icon"><i className='bx bxl-instagram-alt circle-icon bx-tada-hover'></i></a>
                    <a href={deviantart} target="_blank" className="social-icon"><i className='bx bxl-deviantart circle-icon bx-tada-hover'></i></a>
                    <a href={tumblr} target="_blank" className="social-icon"><i className='bx bxl-tumblr circle-icon bx-tada-hover'></i></a>
                </div>
                :
                <div>
                    <Button className="button-link" href={flickr} size="lg">Flickr</Button>
                    <Button className="button-link" href={flickrGroup} size="lg">Flickr Group</Button>
                    <Button className="button-link" href={fb} size="lg">Facebook</Button>
                    <Button className="button-link" href={insta} size="lg">Instagram</Button>
                    <Button className="button-link" href={twitter} size="lg">Twitter</Button>
                    <Button className="button-link" href={youtube} size="lg">Youtube</Button>
                </div>
            }

        </div>
    );
}

export default SocialLinks;