import axios from 'axios';

const flickrURL = "https://api.flickr.com/services/rest/?method=flickr.people.getPublicPhotos&api_key=2f597af72ab997aa3a5e2e5a867c2907&user_id=10448858@N02&per_page=6&page=1&format=json&nojsoncallback=1";
const apiHost = "https://api.everfaery.com";
const customerEndpoint = "/customers/";
const redeliveryEndpoint = "/transaction/deliver/";


export const FlickrAPI = {
	get: async function () {
		const response = await axios.request({
			url: flickrURL,
			method: "GET"
		})

		return (response.data.photos.photo);
	}
}

export const ordersAPI = {
	get: async function (customerID, pageNum) {
		const response = await axios.request({
			url: apiHost + customerEndpoint + customerID + "/orders/" + pageNum,
			method: "GET"
			/*
			headers: {
				'EVERFAERY-APPVERSION': '5',
				"Cache-Control": "no-cache"
			}
			*/
		})

		return (response.data);
	}
}

export const accountAPI = {
	get: async function (customerID) {
		const response = await axios.request({
			url: apiHost + customerEndpoint + customerID + "/account",
			method: "GET"

		})

		return (response.data);
	}
}

export const redeliveryAPI = {
	get: async function (transactionID) {
		const response = await axios.request({
			url: apiHost + redeliveryEndpoint + transactionID,
			method: "GET"
		})

		return (response.status);
	}
}

