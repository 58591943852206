import axios from 'axios';
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ordersAPI, accountAPI, redeliveryAPI } from '../data/api';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';


 
const Redelivery = () => {
	const [isLoadingPurchases, setLoadingPurch] = useState(true);
	const [isLoadingAccount, setLoadingAcct] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams();
	const [account, setAccount] = useState(null);
	const [purchases, setData] = useState(null);
	const [totalPurchases, setTotal] = useState(null);
	const [totalPages, setMaxPages] = useState(1);
	const [activePage, setPage] = useState(1); 
	const [nextDisabled, setNextDisabled] = useState(false); 
	const [prevDisabled, setPrevDisabled] = useState(true); 
	const itemsPerPage = 10;

	const AddProductRow = (row) => {
		const [inProgress, setInProgress] = useState(false);
		const [returnedStatus, setReturnStatus] = useState(null);

		const doRedelivery = (transactionID) => {
			setInProgress(true);
			setReturnStatus(null);

			redeliveryAPI.get(transactionID).then((response) => {
				setInProgress(false);
				setReturnStatus(response);

			});
		}

		useEffect(() => {
			setInProgress(false);
			setReturnStatus(null);
		}, [activePage]);

		return (
			<div id={row.id} className="col-lg-6 col-sm-12 mb-5">
				<div className="row">
					<div className="col col-sm-8">
						<div className="small-text">{row.date}</div>
						<span className="item-name">{row.item_name}</span>

						{(row.recipient_id != row.buyer_id) && <div className="small-text"><i className='bx bxs-gift' ></i> Gift for <b>{row.recipient_name}</b></div>}
					</div>
					<div className="col col-sm-4 text-center ">
						<span className="align-middle">
							<Button disabled={inProgress} className="delivery-Button" type="Button" onClick={() => { doRedelivery(row.id) }}>{inProgress ? "In Progress..." : <span><i className='bx bx-rotate-left' ></i> Redeliver</span> }</Button>
							{returnedStatus == 200 && <div className="deliv-status small-text mt-1"><i className='bx bx-check'></i>Delivered</div>}
							{returnedStatus == 404 && <div className="deliv-status small-text mt-1"><i className='bx bx-x' ></i>Server Error.</div>}
						</span>

					</div>
				</div>
			</div>
		);
	}

	useEffect(() => {
		if (!account) {
			setLoadingAcct(true);
			accountAPI.get(searchParams.get("user")).then((returnData) => {
				if (returnData.status == 200) {
					setAccount(returnData);
				}				
			})
		}

		setLoadingPurch(true);
		ordersAPI.get(searchParams.get("user"), activePage).then((returnData) => {
			if (!totalPurchases) {
				setTotal(returnData.total);
			}
			setData(returnData.orders);
			if (returnData.total == 0) {
				setMaxPages(1);
			}
			else {
				setMaxPages(Math.ceil(returnData.total / itemsPerPage));
			}
			setLoadingAcct(false);
			setLoadingPurch(false);
		})
	}, [activePage]);

	const nextPage = () => {
		if (activePage == 1) {
			setPrevDisabled(false);
		}
		if (activePage >= (totalPages-1)) {
			setNextDisabled(true);
		}
		setPage(activePage + 1);
	}

	const prevPage = () => {
		if (activePage == 2) {
			setPrevDisabled(true);
		}
		if (activePage == totalPages) {
			setNextDisabled(false);
		}
		setPage(activePage - 1);
	}

	const LoadingContent = () => {
		return (
			<div className="text-center">
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</div>
		);
	}

	return (
		<div>
			<div className="page-intro text-center">
				<h3 className="mb-5">Self-Service Redelivery</h3>
				<p>Products that you have purchased for yourself or received as a gift are listed below. If you cannot find your item or redelivery is not working, contact support with details of the item you are requesting redelivery for.</p>
			</div>
			<hr/>
			<div className="mt-5">
				<div className="row">
						<div className="row mb-5">
							<div className="col-lg-3 col-md-12">
								{isLoadingAccount && <LoadingContent />}

								{(!account && !isLoadingAccount) &&
									<div className="text-center">
										Sorry! Could not find your account in our system.
									</div>
								}

								{account &&
									<ul className="profile">
										<li>Hello <strong>{account.name}</strong>.</li>
										<li>Store Credit: L${account.creditBal}</li>
									<li>{(account.is_vip) ? <span><i className='bx bxs-star'></i> VIP Shopper</span> : <span> Standard Shopper</span>}
											<br />
											<span className="small-text"><Link to="/vip">Learn about VIP benefits</Link></span> </li>
										<li className="mt-5"><Link to="/support">Customer Support</Link></li>
									</ul>
								}
							</div>
						<div className="col-lg-9 col-md-12 purchases-table">
							{isLoadingPurchases && <LoadingContent />}

							{purchases && 
								<div>
									<div className="small-text mb-3">Found <strong>{totalPurchases} items.</strong></div>
									<div className="row">
										{purchases.map((row) => (
										<AddProductRow {...row} />
										))}
									</div>
									<div className="row">
										<div className="col text-center">
											<div>
												<Button disabled={prevDisabled} onClick={() => prevPage()}><i className='bx bxs-chevrons-left' ></i></Button>
												<Button disabled={nextDisabled || (totalPages <= 1)} onClick={() => nextPage()}><i className='bx bxs-chevrons-right'></i></Button>
											</div>
											<div className="mt-3 small-text">Page {activePage} of {totalPages}</div>
										</div>
										</div>
								</div>
							}
							</div>
						</div>					
				</div>
			</div>
		</div>
	);
}

export default Redelivery;
