export const faqData = [
    {
      question: "Can I get redelivery for a purchase or gift?",
      answer:
        "Redelivery terminals can be found at the Everfaery mainstore location, or at Everfaery's event booths when possible. These terminals will allow you to access your personal redelivery page containing your purchases and received gifts. Only gift recipients can redeliver their gifted items. If you are still unable to redeliver the item you are looking for, contact support with details.",
    },
    {
      question:
        "Can I get a refund?",
      answer:
        "Refunds are only allowed for two conditions: (1) duplicate purchases, and (2) single-color purchases if the fatpack was purchased within 30 days under the same conditions (i.e. both items must have been purchased at regular price or discounted). To request a refund, contact support with details of the item you are requesting a refund for. Refunds are processed manually within 24-48 hours of your request. For more info about the store policy, please visit the related page below.",
        link: "/policy",
        linkname: "View the Store Policy"
    },
    {
        question:
            "How do I purchase an item as a gift?",
        answer:
            "To purchase an item for another avatar, right-click on the item and select the 'Buy Menu'. You will see a pop-up window with additional purchase options. Click on the 'Buy Gift' option and follow the instructions displayed in local chat."
    },
    {
        question:
            "How can I subscribe or join the group?",
        answer:
            "To see your options to subscribe to receive the latest news from Everfaery, see the related link below.",
        link: "/follow",
        linkname: "Follow Everfaery In-World and On Socials"
    },  
    {
        question:
            "How do I become a VIP?",
        answer:
            "To see details on how to become a VIP, plus an explanation of benefits, see the related link below.",
        link: "/vip",
        linkname: "Learn more about VIP Benefits"
    },
  
    {
      question:
        "Which mesh bodies do you currently provide sizes for? / Will you be adding more sizes in the future?",
      answer:
        "As of 2024, sizes are prioritized for Ebody Reborn + Add-Ons, Meshbody Legacy + Perky, and Maitreya Lara X. Items released prior to 2024 may carry additional sizes such as Inithium Kupra, Slink Hourglass, Belleza Freya and Belleza Isis. New body sizes are always being considered, but support for any size not already listed is not guaranteed.",
    },
    {
      question:
        "Will you update an older item with new sizes?",
      answer:
        "Older items may occasionally be updated with additional sizes when possible, however there is no guarantee of size updates for an item unless otherwise stated. The items selected to recieve size updates is usually not planned in advance, so questions about which items will be updated cannot be answered. Discontinued products are not eligible for size updates.",
    }, 
    {
        question:
            "Where can I buy gift cards?",
        answer:
            "To buy a gift card, visit the mainstore and click on the gift card you wish to purchase. You will receive an empty inactive gift card with instructions to help you complete the purchase.",

    },
    {
        question:
            "How do I use the gift card?",
        answer:
            "To redeem a gift card: visit the mainstore, wear your card, and touch the \"Redeem Gift Card\" terminal. Redeeming the card will add the gift card's value as store credit for your avatar to use at any Everfaery vendor (unless disabled during sales.)",

    },
    {
      question:
        "I am looking for an older item and cannot find it, do you still sell it?",
      answer:
        "Products that are available for purchase can be found in-world at the mainstore location or on the marketplace. Items that were recently released as event exclusives are moved to the mainstore within 1-2 weeks of the event round ending. If you are unable to find the product you are looking for and it is not a recent release, then it has been discontinued and will not be sold directly."
    },
    {
      question:
        "Do you provide support for older/discontinued products or gachas?",
      answer:
        "Support is not provided for items that have been permanently discontinued. Gachas have either been discontinued or converted to normal vendors; any gachas purchased from third parties are not eligible for support or updates."
    },
    {
      question:
        "How can I become a blogger for Everfaery? / How can I request a review copy?",
      answer:
        "Thank you for your interest! You can apply to become a blogger via the interest form below; however, this does not guarantee acceptance or an invite.",
        link: "https://forms.gle/3BULFf1GjywHm36SA",
        linkname: "Everfaery Blogger Interest Form"

    },               
  ];